import { ContentBlockType } from '@tytapp/content';
import { FullLiveScheduleBlock } from './full-live-schedule-block';

export * from './full-live-schedule-block';

export const FULL_LIVE_SCHEDULE_BLOCK_TYPE: ContentBlockType<FullLiveScheduleBlock> = {
    id: 'full-live-schedule',
    label: 'Full Live Schedule',
    icon: 'live_tv',
    template: {
        allStreams: [],
        featuredStream: null,
        otherStreams: [],
        user: null,
        entitled: false,
        liveSchedules: [],
        recentEpisodes: [],
        recentArticles: [],
        ready: false,
        timezoneString: '',
        timezone: ''
    },
    preview: {
        allStreams: [],
        featuredStream: null,
        otherStreams: [],
        user: null,
        entitled: false,
        liveSchedules: [],
        recentEpisodes: [],
        recentArticles: [],
        ready: false,
        timezoneString: '',
        timezone: ''
    },
    categoryId: 'dynamic-content',
    deferredEditorComponent: () => import('./full-live-schedule-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./full-live-schedule-block.view').then(m => m.ViewComponent)
}
